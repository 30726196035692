import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from "../AuthProvider";

function Header() {
    const auth = useAuth();

    if(!auth.token || auth.user?.role !== "ADMIN"){
        return;
    }

    return (
        <div className="row mb-5 border border-bottom border-dark">
            <div className="col-md-5">
                <ul className="nav justify-content-start">
                    <li className="nav-item">
                        <Link to="/activities">
                            <span className="nav-link">Activités</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/artisans">
                            <span className="nav-link">Artisans</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="col-md-2 text-center">
                <ul className="nav justify-content-start">
                    <li className="nav-item">
                        <Link to="/home">
                            <span className="nav-link">Lokalys</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="col-md-5 text-end">
                <ul className="nav justify-content-end">
                    <li className="nav-item">
                        <button onClick={() => auth.logOut()} type="button" className="btn btn-dark">
                            logout
                        </button>
                    </li>      
                </ul>                  
            </div>
        </div>
    );
}

export default Header;