import AxiosInstance from "./AxiosInstance";

class ActivityService {
    async getActivitiesList() {
        const { data } = await AxiosInstance.get(`/activities`);
        return data;
    }

    async getActivityDetail(activityId) {
        const { data } = await AxiosInstance.get(`/activity/${activityId}`);
        return data;
    }

    async createActivity(activity, image, category) {
        //console.log("test")
        const formData = new FormData();
        formData.append('title', activity.title);
        formData.append('description', activity.description);
        formData.append('price', activity.price);
        formData.append('address', activity.address);
        formData.append('postalCode', activity.postalCode);
        formData.append('city', activity.city);
        formData.append('craftManId', activity.craftManId);
        formData.append('categoryId', category);
        formData.append('image', image);

        const {data} = await AxiosInstance.post(`/activity`, formData);

        if (data === undefined || data === null){
            return {success: false, message: "Erreur du serveur"};
        }

        return {success: true, message: ""}
    }
}

const instanceActivityService = new ActivityService();
export default instanceActivityService;
