import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ActivityService from "../api/ActivityService";

function ActivitiesList() {
  const [activities, setActivities] = useState([]);

  async function loadActivitiesList(){
    const response = await ActivityService.getActivitiesList();

    setActivities(response);
  }

  useEffect(() => {
    loadActivitiesList();
  }, []);

  if(!activities){
    return(
      <div className='text-center'>
        <h1>Activité non trouvé</h1>
      </div>
    )
  }

  return (
    <div>
      <div className='row'>
        <div className='col-md-12'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Titre</th>
                <th scope="col">Description</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {activities.map((activity, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{activity.id}</th>
                    <td>{activity.title}</td>
                    <td>{activity.description}</td>
                    <td className='text-end'><Link to={"/editactivity/" + activity.id}><button type="button" className="btn btn-primary">Voir plus</button></Link></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ActivitiesList;