import React from 'react';

function Footer() {
  return (
    <div>

    </div>
    // <div className="text-center fixed-bottom mt-5">
    //   <div
    //     className="rounded-pill px-3 py-1 mb-2 bg-info-subtle d-inline-block"
    //   >
    //     Créé par Charcosset Romain
    //   </div>
    // </div>
  );
}

export default Footer;