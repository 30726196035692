import AxiosInstance from "./AxiosInstance";

class CraftMenService {
    async getCraftMenList() {
        const { data } = await AxiosInstance.get(`/craftmen`);
        return data;
    }

    async getCraftManDetail(craftManId) {
        const { data } = await AxiosInstance.get(`/craftman/${craftManId}`);
        return data;
    }

    async createCraftMan(professionnal, craftMan, image) {
        const result = await AxiosInstance.post(`/professionnal`, {
            professionnal
        });

        if (!result.data.success){
            return {success: result.data.success, message: result.data.message};
        }

        const formData = new FormData();
        formData.append('title', craftMan.title);
        formData.append('description', craftMan.description);
        formData.append('address', craftMan.address);
        formData.append('postalCode', craftMan.postalCode);
        formData.append('city', craftMan.city);
        formData.append('userId', result.data.user.id);
        formData.append('image', image);

        const { data } = await AxiosInstance.post(`/craftman`, formData);

        if (data === undefined || data === null){
            return {success: false, message: "Erreur du serveur"};
        }

        return {success: true, message: ""}
    }

  // async deleteRestaurant(userId) {
  //   const { data } = await AxiosInstance.delete(`/user/${userId}`, {
      
  //   });
  //   return data;
  // }

  // async updateRestaurantUser(restaurantUser) {
  //   const { data } = await AxiosInstance.patch(`/user/${restaurantUser._id}`, {
  //     restaurantUser
  //   });
  //   return data;
  // }

  // async getPlate(plateId) {
  //   const user = await this.getLoggedUser();
  //   const { data } = await AxiosInstance.get(`/user/${user._id}/plate/${plateId}`);
  //   return data;
  // }

  // async updatePlate(plateId, newPlate) {
  //   const user = await this.getLoggedUser();
  //   const { data } = await AxiosInstance.patch(`/user/${user._id}/plate/${plateId}`, {
  //     newPlate
  //   });
  //   return data;
  // }

  // async createPlate(newPlate) {
  //   const user = await this.getLoggedUser();
  //   const { data } = await AxiosInstance.post(`/user/${user._id}/plate`, {
  //     newPlate    
  //   });
  //   return data;
  // }

  // async getRestaurantPlates() {
  //   const user = await this.getLoggedUser();
  //   const { data } = await AxiosInstance.get(`/user/${user._id}/plates`);
  //   return data;
  // }

  // async cancelOrder(orderId) {
  //   const user = await this.getLoggedUser();
  //   const { data } = await AxiosInstance.patch(`/user/${user._id}/order/${orderId}`, {
      
  //   });
  //   return data;
  // }

  // async getRestaurantOrders() {
  //   const user = await this.getLoggedUser();
  //   const { data } = await AxiosInstance.get(`/user/${user._id}/orders`);
  //   return data;
  // }
}

const instanceCraftMenService = new CraftMenService();
export default instanceCraftMenService;
