import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import CraftMenService from "../api/CraftMenService";

function CraftManEdit(props){
  const [craftMan, setCraftMan] = useState(null);
  const params = useParams();

  async function loadCraftManDetail(){
    const craftManId = params.id;

    const response = await CraftMenService.getCraftManDetail(craftManId);

    setCraftMan(response);
  }

  useEffect(() => {
    loadCraftManDetail();
  }, []);

  if(!craftMan){
    return <div className='text-center'><h1>Artisan non trouvé</h1></div>
  }

  return (
    <div>
        <div className='row mb-5'>
            <div className="col-md-4">
                <input
                    className="form-control"
                    type="text"
                    id="CraftMenEdit_Title"
                    name="title"
                    value={craftMan.title}
                    //onChange={handleCraftManInput}
                    required
                />
            </div>
            <div className='col-md-8 text-end'>
                <Link to={"/createactivity/" + craftMan.id}>
                    <button className="btn btn-primary">Créer une activités</button>
                </Link>
            </div>
        </div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <img src={craftMan.image} className="card-img-top" alt="" />
        </div>

        <div className="col-md-6 col-sm-12">
            <div className='row mb-3'>
                <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        id="CraftMenEdit_Description"
                        name="description"
                        value={craftMan.description}
                        //onChange={description}
                        required
                    />
                </div>
            </div>
            <div className='row'>
                <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        id="CraftMenEdit_Address"
                        name="address"
                        value={craftMan.address}
                        //onChange={description}
                        required
                    />
                </div>
                <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        id="CraftMenEdit_PostalCode"
                        name="postalCode"
                        value={craftMan.postalCode}
                        //onChange={description}
                        required
                    />
                </div>
                <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        id="CraftMenEdit_City"
                        name="city"
                        value={craftMan.city}
                        //onChange={description}
                        required
                    />
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default CraftManEdit;