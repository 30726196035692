import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import AxiosInstance from "./api/AxiosInstance";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("accessAdminUser")) || null);
    const [token, setToken] = useState(localStorage.getItem("accessAdminToken") || "");
    const navigate = useNavigate();

    // useEffect(() => {
    //     console.log("diot",user);
    //     console.log("diot2",token);
    //   }, [user, token]);

    const signIn = async (data) => {
        try {
            const response = await AxiosInstance.post(`/signIn`, {
                data
            });

            if (response.data) {
                if (!response.data.success){
                    return { success: false, message: response.data.message };
                }
                
                if (response.data.user.role !== "ADMIN"){
                    return { success: false, message: "Vous n'êtes pas autoriser à accèder à ce site" };
                }

                const userWithoutPassword = { ...response.data.user };
                delete userWithoutPassword.password;

                setUser(userWithoutPassword);
                setToken(response.data.token);
                localStorage.setItem("accessAdminUser", JSON.stringify(userWithoutPassword));
                localStorage.setItem("accessAdminToken", response.data.token);
                navigate("/home");
                return { success: true, message: "" };
            }
        } catch (err) {
            console.error(err);
        }
    };

    const logOut = () => {
        setUser(null);
        setToken("");
        localStorage.removeItem("accessAdminUser");
        localStorage.removeItem("accessAdminToken");
        navigate("/");
    };

    return (
        <AuthContext.Provider value={{ token, user, signIn, logOut }}>
        {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
