import { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import ActivityService from "../api/ActivityService";
import CraftMenService from "../api/CraftMenService";
import CategoryService from "../api/CategoryService";

function ActivityCreate() {
    const params = useParams();
    const navigate = useNavigate();

    const [image, setImage] = useState()

    const [activity, setActivity] = useState({
        title: "",
        description: "",
        price: "",
        address: "",
        postalCode: "",
        city: "",
        craftManId: params.craftmanid
    });

    const [craftMan, setCraftMan] = useState(null);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);

    async function loadCraftManDetail(){
      const craftManId = params.craftmanid;
  
      const response = await CraftMenService.getCraftManDetail(craftManId);

      setActivity((prev) => ({
        ...prev,
        address: response.address,
        postalCode: response.postalCode,
        city: response.city,
      }));
  
      setCraftMan(response);
    }

    async function loadCategories(){
      const response = await CategoryService.getCategoriesList();

      setCategories(response);
    }
  
    useEffect(() => {
      loadCraftManDetail();
      loadCategories();
    }, []);

    const handleSubmitEvent = async (e) => {
        e.preventDefault();

        const result = await ActivityService.createActivity(activity, image, selectedSubCategory);

        if (!result.success){
            const errorMessageDiv = document.getElementById("errorMessage");
            errorMessageDiv.textContent = result.message;
            return;
        }

        navigate("/activities");
    };

    const handleActivityInput = (e) => {
        const { name, value } = e.target;
        setActivity((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

  return (
    <div>
        <div className="mb-3 text-center">
          <h1>Création de l'activité</h1>
        </div>
        <form onSubmit={handleSubmitEvent} encType="multipart/form-data">
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="ActivityCreate_Title" className="form-label">Titre: *</label>
                        <input
                            className="form-control"
                            type="text"
                            id="ActivityCreate_Title"
                            name="title"
                            onChange={handleActivityInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="ActivityCreate_Description" className="form-label">Description: *</label>
                        <textarea
                            className="form-control"
                            id="ActivityCreate_Description"
                            name="description"
                            rows="3"
                            onChange={handleActivityInput}
                            required
                        ></textarea>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="ActivityCreate_Price" className="form-label">Prix: *</label>
                        <input
                            className="form-control"
                            type="number"
                            id="ActivityCreate_Price"
                            name="price"
                            step="0.01"
                            min="0"
                            onChange={handleActivityInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="ActivityCreate_Categories" className="form-label">Catégorie: *</label>
                      <select id="ActivityCreate_Categories" className="form-control" onChange={(e) => {setSelectedCategory(e.target.value);}} required>
                        <option value="">Sélectionner une catégorie</option>
                        {categories && categories.filter((category) => category.parentId === null).map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="ActivityCreate_SubCategories" className="form-label">Sous catégorie: *</label>
                      <select id="ActivityCreate_SubCategories" className="form-control" onChange={(e) => {setSelectedSubCategory(e.target.value);}} disabled={!selectedCategory} required>
                        <option value="">Sélectionner une sous catégorie</option>
                        {categories.filter((category) => `${category.parentId}` === selectedCategory).map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="ActivityCreate_Address" className="form-label">Adresse: *</label>
                        <input
                            className="form-control"
                            type="text"
                            id="ActivityCreate_Address"
                            name="address"
                            value={craftMan?.address ?? ""}
                            onChange={handleActivityInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="ActivityCreate_PostalCode" className="form-label">Code postal: *</label>
                        <input
                            className="form-control"
                            type="text"
                            id="ActivityCreate_PostalCode"
                            name="postalCode"
                            value={craftMan?.postalCode ?? ""}
                            onChange={handleActivityInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="ActivityCreate_City" className="form-label">Ville: *</label>
                        <input
                            className="form-control"
                            type="text"
                            id="ActivityCreate_City"
                            name="city"
                            value={craftMan?.city ?? ""}
                            onChange={handleActivityInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="ActivityCreate_Image" className="form-label">Photo: *</label>
                        <input
                            className="form-control"
                            type="file"
                            filename={image} 
                            onChange={e => setImage(e.target.files[0])} 
                            id="ActivityCreate_Image"
                            accept="image/jpg, image/jpeg, image/png"
                            required
                        />
                    </div>
                </div>
            </div>
            
            <div className="text-center">
                <button type="submit" className="btn btn-primary">Submit</button>
                <div id="errorMessage" className="text-danger"></div>
            </div>
        </form>
    </div>
  );
}

export default ActivityCreate;