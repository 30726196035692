import axios from "axios";
import { useAuth } from "../AuthProvider";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessAdminToken");
  config.headers.Authorization = token;
  
  if (config.data instanceof FormData) {
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error?.response?.status === 401) {
      const auth = useAuth();
      auth.logOut();
    }

    return error;
  }
);

export default axiosInstance;
