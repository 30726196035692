import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./screens/Home";
import SignIn from "./screens/SignIn";
import ActivitiesList from "./screens/ActivitiesList.js";
import CraftMenList from "./screens/CraftMenList.js";
import ActivityCreate from "./screens/ActivityCreate.js";
import CraftManCreate from "./screens/CraftManCreate.js";
import ActivityEdit from "./screens/ActivityEdit.js";
import CraftManEdit from "./screens/CraftManEdit.js";

import AuthProvider from "./AuthProvider.js";
import PrivateRouteAdmin from "./router/route.js";

import Header from "./components/Header.js";
import Footer from "./components/Footer.js";

function App() {
  return (
    <div className="container mt-3 mb-5">
      <Router>
        <AuthProvider>

          <Header />
          
          <Footer />

          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route element={<PrivateRouteAdmin />}>
              <Route path="/home" element={<Home />} />
              <Route path="/activities" element={<ActivitiesList />} />
              <Route path="/artisans" element={<CraftMenList />} />
              <Route path="/createactivity/:craftmanid" element={<ActivityCreate />} />
              <Route path="/createcraftmen" element={<CraftManCreate />} />
              <Route path="/editactivity/:id" element={<ActivityEdit />} />
              <Route path="/editcraftmen/:id" element={<CraftManEdit />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
