import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ActivityService from "../api/ActivityService";

function ActivityEdit(props){
  const [activity, setActivity] = useState(null);
  const params = useParams();

  async function loadActivityDetail(){
    const activityId = params.id;

    const response = await ActivityService.getActivityDetail(activityId);

    setActivity(response);
  }

  useEffect(() => {
    loadActivityDetail();
  }, []);

  if(!activity){
    return <div className='text-center'><h1>Activité non trouvé</h1></div>
  }

  return (
    <div>
        <div className='row mb-5'>
            <div className="col-md-4">
                <input
                    className="form-control"
                    type="text"
                    id="ActyvityEdit_Title"
                    name="title"
                    value={activity.title}
                    //onChange={handleCraftManInput}
                    required
                />
            </div>
        </div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <img src={activity.image} className="card-img-top" alt="" />
        </div>

        <div className="col-md-6 col-sm-12">
            <div className='row mb-3'>
                <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        id="ActyvityEdit_Description"
                        name="description"
                        value={activity.description}
                        //onChange={description}
                        required
                    />
                </div>
            </div>
            <div className='row mb-3'>
                <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        id="ActyvityEdit_Address"
                        name="address"
                        value={activity.address}
                        //onChange={description}
                        required
                    />
                </div>
                <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        id="ActyvityEdit_PostalCode"
                        name="postalCode"
                        value={activity.postalCode}
                        //onChange={description}
                        required
                    />
                </div>
                <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        id="ActyvityEdit_City"
                        name="city"
                        value={activity.city}
                        //onChange={description}
                        required
                    />
                </div>
            </div>
            <div className='row mb-3'>
                <div className="col-md-4">
                    <input
                        className="form-control"
                        type="text"
                        id="ActyvityEdit_Price"
                        name="description"
                        value={activity.price}
                        //onChange={description}
                        required
                    />
                </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ActivityEdit;