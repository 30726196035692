import { useState } from "react";
import { useAuth } from "../AuthProvider";

const SignIn = () => {
  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const auth = useAuth();

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const errorMessageDiv = document.getElementById("errorMessage");

    if (input.email !== "" && input.password !== "") {
      const result = await auth.signIn(input);

      if (!result.success){
        errorMessageDiv.textContent = result.message;
        return;
      }

      return;
    }

    errorMessageDiv.textContent = "Veuillez rempire tous les champs";
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div>
        <div className="mb-3 text-center">
          <h1>Connexion</h1>
        </div>
        <div className="col-md-6 mx-auto">
          <form onSubmit={handleSubmitEvent}>
            <div className="mb-3">
              <label htmlFor="SignIn_Email" className="form-label">Email: *</label>
              <input
                className="form-control"
                type="email"
                id="SignIn_Email"
                name="email"
                placeholder="example@gmail.com"
                onChange={handleInput}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="SignIn_Password" className="form-label">Mot de passe: *</label>
              <input
                className="form-control"
                type="password"
                id="SignIn_Password"
                name="password"
                onChange={handleInput}
                required
              />
            </div>
            <div className="text-center">
              <button type="submit" className="btn btn-primary">Submit</button>
              <div id="errorMessage" className="text-danger"></div>
            </div>
          </form>
        </div>
    </div>
  );
};

export default SignIn;