import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CraftMenService from "../api/CraftMenService";

function CraftManCreate() {
    const [image, setImage] = useState()

    const [professionnal, setProfessionnal] = useState({
        lastName: "",
        firstName: "",
        email: "",
        phoneNumber: "",
        role: "PROFESSIONNAL"
    });

    const [craftMan, setCraftMan] = useState({
        title: "",
        description: "",
        address: "",
        postalCode: "",
        city: ""
    });

    const navigate = useNavigate();

    const handleSubmitEvent = async (e) => {
        e.preventDefault();
    
        const result = await CraftMenService.createCraftMan(professionnal, craftMan, image);

        if (!result.success){
            const errorMessageDiv = document.getElementById("errorMessage");
            errorMessageDiv.textContent = result.message;
            return;
        }

        navigate("/artisans");
    };

    const handleUserInput = (e) => {
        const { name, value } = e.target;
        setProfessionnal((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleCraftManInput = (e) => {
        const { name, value } = e.target;
        setCraftMan((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

  return (
    <div>
        <div className="mb-3 text-center">
          <h1>Création artisan</h1>
        </div>
        <form onSubmit={handleSubmitEvent} encType="multipart/form-data">
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="CreateCraftMen_Title" className="form-label">Titre: *</label>
                        <input
                            className="form-control"
                            type="text"
                            id="CreateCraftMen_Title"
                            name="title"
                            onChange={handleCraftManInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="CreateCraftMen_Description" className="form-label">Description: *</label>
                        <textarea
                            className="form-control"
                            id="CreateCraftMen_Description"
                            name="description"
                            rows="3"
                            onChange={handleCraftManInput}
                            required
                        ></textarea>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="CreateCraftMen_Address" className="form-label">Adresse: *</label>
                        <input
                            className="form-control"
                            type="text"
                            id="CreateCraftMen_Address"
                            name="address"
                            onChange={handleCraftManInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="CreateCraftMen_PostalCode" className="form-label">Code postal: *</label>
                        <input
                            className="form-control"
                            type="text"
                            id="CreateCraftMen_PostalCode"
                            name="postalCode"
                            onChange={handleCraftManInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="CreateCraftMen_City" className="form-label">Ville: *</label>
                        <input
                            className="form-control"
                            type="text"
                            id="CreateCraftMen_City"
                            name="city"
                            onChange={handleCraftManInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="CreateCraftMen_Image" className="form-label">Photo: *</label>
                        <input
                            className="form-control"
                            type="file"
                            filename={image} 
                            onChange={e => setImage(e.target.files[0])} 
                            id="CreateCraftMen_Image"
                            accept="image/jpg, image/jpeg, image/png"
                            required
                        />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label htmlFor="CreateCraftMen_LastName" className="form-label">Nom: *</label>
                        <input
                            className="form-control"
                            type="text"
                            id="CreateCraftMen_LastName"
                            name="lastName"
                            onChange={handleUserInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="CreateCraftMen_FirstName" className="form-label">Prénom: *</label>
                        <input
                            className="form-control"
                            type="text"
                            id="CreateCraftMen_FirstName"
                            name="firstName"
                            onChange={handleUserInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="CreateCraftMen_Email" className="form-label">Email: *</label>
                        <input
                            className="form-control"
                            type="email"
                            id="CreateCraftMen_Email"
                            name="email"
                            placeholder="example@gmail.com"
                            onChange={handleUserInput}
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="CreateCraftMen_PhoneNumber" className="form-label">Numéro de téléphone: *</label>
                        <input
                            className="form-control"
                            type="tel"
                            id="CreateCraftMen_PhoneNumber"
                            name="phoneNumber"
                            onChange={handleUserInput}
                            required
                        />
                    </div>
                </div>
            </div>
            
            <div className="text-center">
                <button type="submit" className="btn btn-primary">Submit</button>
                <div id="errorMessage" className="text-danger"></div>
            </div>
        </form>
    </div>
  );
}

export default CraftManCreate;