import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../AuthProvider";

const PrivateRouteAdmin = () => {
  const user = useAuth();
  if (!user.token || user.user?.role !== "ADMIN") return <Navigate to="/" />;
  return <Outlet />;
};

export default PrivateRouteAdmin;