import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import CraftMenService from "../api/CraftMenService";

function CraftMenList() {
  const [craftMen, setCraftMen] = useState([]);

  async function loadCraftMenList(){
    const response = await CraftMenService.getCraftMenList();

    setCraftMen(response);
  }

  useEffect(() => {
    loadCraftMenList();
  }, []);

  return (
    <div>
      <div className="text-center mb-5">
        <Link to="/createcraftmen">
          <button className="btn btn-primary">Créer un artisan</button>
        </Link>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Titre</th>
                <th scope="col">Description</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {craftMen.map((craftMan, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{craftMan.id}</th>
                    <td>{craftMan.title}</td>
                    <td>{craftMan.description}</td>
                    <td className='text-end'><Link to={"/editcraftmen/" + craftMan.id}><button type="button" className="btn btn-primary">Voir plus</button></Link></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CraftMenList;