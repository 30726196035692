import AxiosInstance from "./AxiosInstance";

class CategoryService {
    async getCategoriesList() {
        const { data } = await AxiosInstance.get(`/categories`);
        return data;
    }
}

const instanceCategoryService = new CategoryService();
export default instanceCategoryService;
